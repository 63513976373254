

.live_stream {
    // display: flex;
    // flex-direction: row;
    // background-color: #000;
    // height: 210px;
    // padding-top: 44px;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    button {
        color: red !important;
    }
    .accordion_container {
        background: white;
        z-index: 999;
        position: relative;
        .accordion {
            padding: 10px;
            background-color: #fff;
        }
    }

    .Collapsible {
        position: absolute;
        top: 0;
        // z-index: 1;
        width: 100%;
    }

    .video-react .video-react-big-play-button{
        top: 50%;
        left: 50%;
        margin-left: -30px;
        margin-top: -24px;
    }
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.sports_bet {
    width: 100%;
    display: flex;
    flex-direction: column;

    .table_block {
        overflow-x: scroll;
    }

    :focus {
        outline: none;
    }
}

.accordion__button {
    background: #304352;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #304352, #d7d2cc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #304352, #d7d2cc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 12px;
    color: #fff;
    font-size: 14px;
}

.accordion__button:hover {
    background: #304352;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #d7d2cc, #304352);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #d7d2cc, #304352); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #444;
}

.is-open {

    .accordion__button:before {
        transform: rotate(45deg);
    }
}

.accordion {

    .live_heading {

        span {
            margin-left: 13px;
            font-size: 14px;
        }

        

        .accordion__button:after {
            background: url(../../images/broadcast.svg);
            margin-right: 0;
        }
    }

    .onlyDesktopView {
        display: inline;
    }

    .onlyMobileView {
        display: none;
    }

    .accordion__button:after {
        content: '';
        width: 25px;
        height: 25px;
        background: url(../../images/default.svg);
        background-repeat: no-repeat;
        margin-right: 12px;
        float: left;
    }

    
    .accordion__button:before {
        float: right;
        width: 7px;
        height: 7px;
        margin-top: 4px;
        color: #7b7b7b;
    }

    .accordion__button:focus {
        outline: none;
    }

    .accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg);
    }

    .accordion__heading {
        position: sticky;
        top: 0;
        border-bottom: 1px solid #dabcbc;
        z-index: 10;
    }

    .accordion__heading:nth-last-child(1) {
        border-bottom: none;
    }

    .sports_baseball {
        
        .accordion__button:after {
            background: url(../../images/baseball_player.svg);
        }
    }

    .sports_default {
        
        .accordion__button:after {
            background: url(../../images/default.svg);
        }
    }

    .sports_cricket {
        
        .accordion__button:after {
            background: url(../../images/cricket.svg);
        }
    }

    .sports_soccer {
        
        .accordion__button:after {
            background: url(../../images/soccer.svg);
        }
    }

    .sports_horse_racing {
        
        .accordion__button:after {
            background: url(../../images/horse_riding.svg);
        }
    }
}

.video-js {
    width: 100%;
    height: calc(100vh - 55px);
}

.table_block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // border: 1px solid #d6d6d6;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
    overflow-x: scroll;

    table {
        flex: auto;
        border-spacing: 0;
        border-color: transparent;
        color: #676767;
        width: 100%;

        tr:nth-last-child(1) {

            td {
                border-bottom: none;
            }
        }

        td {
            padding: 15px 10px;
            // border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            font-size: 14px;
            cursor: pointer;
        }

        .play_btn {
            cursor: pointer;
            font-size: 0;
            text-align: center;
            width: 120px;
            
        }

        .play_btn:hover {
            background-color: #f1f1f1;
        }

        .play_btn:before {
            content: "";
            width: 25px;
            height: 25px;
            background: url(../../images/play.svg);
            background-repeat: no-repeat;
            padding: 10px;
            background-size: contain;
            background-position: center;
        }

        td:nth-last-child(1) {
            border-right: none;
        }

        .goal_color {
            color: green;
            font-weight: 700;
        }

        .penalty_color {
            color: red;
            font-weight: 700;
        }

        .red_card {
            color: red;
            font-weight: 700;
        }

        .yellow_card {
            color: #bea422;
            font-weight: 700;
        }

        .table_title {
            font-weight: 500;
            background-color: #e0dcdc;
            position: sticky;
            position: -webkit-sticky;
            top: 0em;
            border-radius: 5px 5px 0 0;

            th {
                border-right: 1px solid #999;
                border-bottom: 1px solid #999;
                border-top: 1px solid #999;
                font-size: 14px;
                font-weight: 600;
                color: #545454;
                padding: 15px 10px;
                text-align: left;
            }

            th:first-child {
                border-left: 1px solid #999;
                border-radius: 5px 0px 0 0;
            }

            th:last-child {
                border-radius: 0px 5px 0 0;
            }

        }
    }
}


.accordion__panel {
    background: #ffffff;
    // opacity: 0.9;
}


.video-react .video-react-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 1.5em;
    width: 3em;
    display: block;
    position: absolute;
    top: 30%;
    left: 45%;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 0.3em;
    transition: all 0.4s;
    @media only screen and (max-width: 600px) {
        left: 30%;
    }
}

.live_heading {
    background: linear-gradient(to right, #7d0204, #b21318, #7d0204);
    color: #fff;
    transition: all 0.3s ease-in-out 0s;
}

.video-react {
    margin-top: 45px;
    padding-top: calc(100vh - 45px)!important;
}
.video-react:focus {
    outline: none;
}
:focus {
    outline: -webkit-focus-ring-color auto 1px;
}

@media only screen and (max-width: 768px){

    .live_stream .Collapsible {
        max-height: 100vh;
        overflow: scroll;
    }

    .accordion__panel {
        padding: 10px;
    }

    .accordion__button {
        padding: 10px;
        font-size: 14px;
    }

    .accordion__button:before {
        height: 7px;
        width: 7px;
        margin-top: 4px;
    }

    .accordion {

        .accordion__button:after {
            width: 20px;
            height: 20px;
        }
        
        .live_heading {
            
            .accordion__button {
                padding: 12px;
            }
        }
        .onlyDesktopView {
            display: none;
        }
    
        .onlyMobileView {
            display: inline;
        }
    }

    .table_block table .table_title th {
        padding: 10px 10px;
    }

    .table_block table td {
        padding: 10px 10px;
    }

    .table_block table .play_btn:before {
        width: 15px;
        height: 15px;
        padding: 8px;
    }

    .video-react {
        // margin-top: 0px;
        margin-top: 44px;
    }
}

@media only screen and (max-width: 480px){
    .table_block table { 
        .play_btn {
            width: 70px;
        }
    }
    .live_stream .video-react .video-react-big-play-button {
        margin-left: -43px;
    }
.accordion .live_heading .accordion__button:after {
    position: absolute;
    left: 11px;
    top: 12px;
}

.accordion .live_heading span {
    margin-left: 0px;
}

.accordion .live_heading .accordion__button {
    padding-left: 38px;
}
}

.noDataContainer {
    background-color: #999;
    width: 100%;
    height: 100%;
    padding-top: 75px;
    position: absolute;
    top: 0;
    z-index: 1;
    h1 {
        color: black;
        font-size: 14px;
        text-align: center;
    }
}

.Collapsible__contentOuter {
    overflow: scroll !important;
}

.is-open + .Collapsible__contentOuter {
    height: 100vh!important;
}